import React from "react"
import { BaseButton, Text } from "../../../vanilla"
import { searchButtonItem } from "./styles.css"
import { CloseIcon } from "../../icons"

interface RefinementItemProps {
    refinement: string
    refinementAttr: string | number
    index: number
    refine: (value: string) => void
}

export const RefinementItem = ({ refinement, refinementAttr, index, refine }: RefinementItemProps) => {

    return (
        <BaseButton
            key={`${refinementAttr}-${refinement}-${index}`}
            display="inline-flex"
            extraClassName={searchButtonItem}
            gap="4px"
            onClick={() => refine(refinement)}
            cursor="pointer"
            alignItems="center"
            variant="unstyled"
        >
            <Text variant="unstyled" fontSize="inherit" fontWeight="inherit" color="accent3">
                {refinement}
            </Text>
            <CloseIcon width="12px" color="accent0" />
        </BaseButton>
    )
}