import {ShopperProducts} from 'commerce-api'
import {ProductModel} from '../../store/ProductStore'
import {getAppOrigin} from '@salesforce/pwa-kit-react-sdk/utils/url'
import {formatProductName} from '../utils'

export type StructuredListItem = {
  '@type': 'ListItem'
  position: number
  item: {
    '@id': string | undefined
    name: string | undefined
  }
}

export type StructuredBreadcrumbList = {
  '@context': 'https://schema.org'
  '@type': 'BreadcrumbList'
  itemListElement: Array<StructuredListItem>
}

export type StructuredOffer = {
  '@type': 'Offer'
  availability: 'https://schema.org/InStock'
  price: number | undefined
  priceCurrency: string | undefined
}

export type Rating = {
  bestRating: string
  ratingValue: string
  worstRating: string
}

export type Review = {
  author: string
  datePublished: string
  reviewBody: string
  name: string
  reviewRating: Rating
}

export type StructuredRating = Rating & {
  '@type': 'Rating'
}

export type StructuredReview = Review & {
  '@type': 'Review'
}

export type StructuredAggregateRating = {
  '@type': 'AggregateRating'
  ratingValue: string
  reviewCount: string
}

export type StructuredProduct = {
  '@type': 'Product'
  image: string | Record<string, string | Record<string, string>> | undefined
  url: string
  name: string | undefined
  brand?: string
  offers: StructuredOffer
  aggregateRating?: StructuredAggregateRating
  reviews?: StructuredReview[]
}

export type StructuredItemList<ItemT> = {
  '@context': 'https://schema.org'
  '@type': 'ItemList'
  url: string
  numberOfItems: string
  itemListElement: Array<ItemT>
}

export type StructuredProductList = StructuredItemList<StructuredProduct>

export type StructuredDataItem =
  | StructuredBreadcrumbList
  | StructuredItemList<StructuredProductList>
  | StructuredProduct

type StructuredDataType = Array<StructuredDataItem>

export default StructuredDataType

export const buildListItemStructuredData = (
  listItem: ShopperProducts.PathRecord,
  position: number
): StructuredListItem => ({
  '@type': 'ListItem',
  position: position,
  item: {
    '@id': listItem.id,
    name: listItem.name,
  },
})

export const buildBreadcrumbsStructuredData = (crumbs: any): StructuredBreadcrumbList => {
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      ...(crumbs || []).map((listItem: ShopperProducts.PathRecord, i: number) =>
        buildListItemStructuredData(
          {
            id: listItem.url,
            name: listItem.name,
          },
          i + 2
        )
      ),
    ],
  }
}

export const buildStructuredOfferData = (price: number, currency: string): StructuredOffer => ({
  '@type': 'Offer',
  availability: 'https://schema.org/InStock',
  price,
  priceCurrency: currency,
})

export const buildStructuredAggregateRatingData = (
  productRating: number,
  productReviewCount: number
): StructuredAggregateRating => {
  return {
    '@type': 'AggregateRating',
    ratingValue: productRating?.toString(),
    reviewCount: productReviewCount?.toString(),
  }
}

export const buildStructuredRatingData = ({
  bestRating,
  ratingValue,
  worstRating,
}: Rating): StructuredRating => ({
  '@type': 'Rating',
  bestRating,
  ratingValue,
  worstRating,
})

export const buildStructuredReviewData = ({
  author,
  datePublished,
  reviewBody,
  name,
  reviewRating,
}: Review): StructuredReview => ({
  '@type': 'Review',
  author,
  datePublished,
  reviewBody,
  name,
  reviewRating: buildStructuredRatingData(reviewRating),
})

export const buildProductModelStructured = (product: ProductModel): StructuredProduct => {
  const {image_groups, name, id, brand, imageGroups, price, productRating, productReviewCount} =
    product
  const url = `${getAppOrigin()}/p/${formatProductName(name)}/${id}.html`

  const image = image_groups?.[0]?.images?.[0] || imageGroups?.[0]?.images?.[0]
  const priceForData = price?.GBP ?? 0

  return {
    '@type': 'Product',
    image: {
      '@type': 'ImageObject',
      contentUrl: image?.dis_base_link,
      license: 'https://iceland.co.uk',
      acquireLicensePage: 'https://iceland.co.uk',
      creditText: 'Iceland',
      creator: {
        '@type': 'Organization',
        name: 'Iceland',
      },
      copyrightNotice: 'Iceland',
    },
    url,
    brand,
    name,
    offers: buildStructuredOfferData(priceForData, 'GBP'),
    aggregateRating: buildStructuredAggregateRatingData(productRating, productReviewCount),
  }
}

export const buildProductListStructuredData = (
  productsList: ProductModel[],
  pathname = ''
): StructuredProductList => {
  return {
    '@context': 'https://schema.org',
    '@type': 'ItemList',
    url: getAppOrigin() + pathname,
    numberOfItems: productsList.length.toString(),
    itemListElement: productsList.map((product) => buildProductModelStructured(product)),
  }
}
