import React, {useRef} from 'react'
import {Box, Flex, Stack, Text, BaseButton} from '../../../../../vanilla'
import {ChevronLeftIcon, ChevronRightIcon} from '../../../../icons'
import {REFINEMENTS_MENU, SEARCH_DEFAULT_REFINEMENTS} from '../../../../../utils/constants'
import {useClipDirectionAnimation} from '../../../../../hooks/use-clip-direction-animation'
import {Link} from '../../../../link'
import { useClearRefinements, useRefinementList } from 'react-instantsearch-hooks-web'
interface RefinementsListItemProps<RefinementItem> {
  label: string
  lastItem: boolean
  isInDrawer?: boolean
  items: RefinementItem[]
  highlightedLabels?: string[]
  checkIcon: boolean
  alwaysHighlighted: boolean
  hasCategories?: boolean
}

const ANIMATION_DURATION_MS = 250
export const RefinementsListItem = <
  RefinementItem extends {
    value: string
    label: string
    isRefined?: boolean
    path?: string
    isTopLevelCategory?: boolean
    name?: string
  },
>(
  props: RefinementsListItemProps<RefinementItem>,
) => {
  const {label, lastItem, isInDrawer, items, highlightedLabels} = props
  const contentRef = useRef<HTMLDivElement | null>(null)

  const {toggleAnimation, animationCSSProperties} = useClipDirectionAnimation(contentRef, 'left', {
    duration: ANIMATION_DURATION_MS,
  })

  const {refine: PriceRefinements} = useRefinementList(SEARCH_DEFAULT_REFINEMENTS.PRICE)
  const {refine: BrandRefinements} = useRefinementList(SEARCH_DEFAULT_REFINEMENTS.BRAND)
  const {refine: ClearPrice} = useClearRefinements({includedAttributes: [SEARCH_DEFAULT_REFINEMENTS.PRICE.attribute]})

  function refinePath(label: string, item: RefinementItem) {  
    if (label === 'Price') {
      // Remove previous price refinement to ensure one price refinement is set at a time
      ClearPrice()
      // Refine with new value
      PriceRefinements(item.value)
    }
    else if (label === 'Category') {

      const categoryPath = item?.path
        ? item?.path
        : `/${
            item?.label
              ? item?.label.toLowerCase().replace(/\s/g, '-').replace(/£/g, '')
              : item?.name?.toLowerCase()?.replace(/\s/g, '-').replace(/£/g, '')
          }`; 
        return categoryPath 
    }
    else if (label === 'Brand') {
BrandRefinements(item.value)
    }
  }
  return (
    <Box as="li" cursor="pointer">
      <Box
        padding="16px"
        position="relative"
        border="1px"
        borderBottom={lastItem && !isInDrawer ? '1px' : '0px'}
        borderColor="gray200"
        paddingRight="36px"
        onClick={toggleAnimation}
      >
        <Text variant="text3" lineHeight="short">
          {label}
        </Text>
        {highlightedLabels?.map((label, idx) => (
          <>
            <Text
              as="span"
              variant="text1"
              lineHeight="none"
              color="accent3"
              key={`selected-refinement-${label}`}
            >
              {label}
              {idx !== highlightedLabels.length - 1 ? ', ' : null}
            </Text>
          </>
        ))}
        <ChevronRightIcon
          boxSize="16px"
          position="absolute"
          style={{
            right: 16,
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        />
      </Box>

      <Box
        ref={contentRef}
        position="absolute"
        top="0px"
        left="0px"
        width="full"
        minHeight="full"
        backgroundColor="white"
        zIndex="docked"
        borderBottom="1px"
        borderColor="gray200"
        style={{
          ...animationCSSProperties,
        }}
      >
        <Flex
          align="center"
          onClick={toggleAnimation}
          padding="16px"
          gap="16px"
          border="1px"
          borderBottom="1px"
          borderColor="gray200"
          backgroundColor={isInDrawer ? 'gray50' : 'white'}
        >
          <ChevronLeftIcon boxSize="16px" />

          <Text
            variant="unstyled"
            fontSize={isInDrawer ? 'xl' : 'md'}
            lineHeight="tall"
            weight="normal"
            marginX={isInDrawer ? 'auto' : undefined}
          >
            {label}
          </Text>
        </Flex>
        <Stack
          spacing="0px"
          as="ul"
          overflowX="hidden"
          overflowY="auto"
          style={{maxHeight: isInDrawer ? window?.innerHeight - 65 : REFINEMENTS_MENU.HEIGHT}}
        >
          {items?.map((item, idx) => (
            <Box as="li" key={item.name}>
              <Stack justifyContent="space-between">
                  <BaseButton
                    as={Link}
                    onClickCapture={(e) => { if (label !== 'Category') {
                      e.preventDefault()
                     refinePath(label, item);
                    }
                    }}
                    to={label === "Category" ? refinePath(label, item):''}
                    variant="unstyled"
                    backgroundColor="white"
                    height="36px"
                    paddingY="4px"
                    paddingLeft="12px"
                    justifyContent="flex-start"
                  >
                    <Text
                      variant="text4"
                      style={{
                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        overflowWrap: 'break-word',
                      }}
                    >

                      {item.label ? item.label : item.name}
                    </Text>
                    <ChevronRightIcon boxSize="16px" marginLeft="auto" />
                  </BaseButton>
              </Stack>
            </Box>
          ))}
        </Stack>
      </Box>
    </Box>
  )
}
