import {RefObject, useEffect, useRef, useState} from 'react'
import {useRect} from '@reach/rect'
import {ObjectValues} from '../types/utils'

const filterAnimationMap = {
  SHOW: 'show',
  HIDE: 'hide',
} as const

const directionToRectProperty = {
  left: 'width',
  top: 'height',
} as const

type FilterAnimationType = ObjectValues<typeof filterAnimationMap> | null

export const useClipDirectionAnimation = (
  elementRef: RefObject<Element>,
  direction: 'left' | 'top',
  options: {
    duration: number
    showStateDirectionValue?: number
  },
  outsideClickAnimation?: boolean,
  onAnimationChange?: (animation: FilterAnimationType) => void
) => {
  const animationStateRef = useRef<FilterAnimationType>(null)

  const rect = useRect(elementRef, {observe: true})
  const [animationDirection, setAnimationDirection] = useState<number>(0)
  const [animationClip, setAnimationClip] = useState<string | undefined>(undefined)

  const [animation, setAnimation] = useState<FilterAnimationType>(null)

  useEffect(() => {
    if (!rect) return
    setAnimationDirection(buildDirectionCSSValue())
    setAnimationClip(buildClipCSSValue())
  }, [rect?.width, rect?.height, animation])

  useEffect(() => {
    const onClick = ({target}: any) => {
      if (
        animationStateRef?.current === filterAnimationMap.SHOW &&
        !elementRef?.current?.contains(target)
      ) {
        switch (direction) {
          case 'top':
            setAnimationClip(clipRuleTemplate(`0px, ${rect?.width}px, ${rect?.height}px, 0px`))
            break
          case 'left':
            setAnimationClip(clipRuleTemplate(`0px, ${rect?.width}px, ${rect?.height}px, 0px`))
            break
        }
        requestAnimationFrame(() => {
          hideAnimation()
        })
      }
    }

    if (!outsideClickAnimation) return

    if (elementRef?.current && rect) {
      document.addEventListener('click', onClick, {capture: true})
    }

    return () => document.removeEventListener('click', onClick)
  }, [rect])

  const buildDirectionCSSValue = (): number => {
    if (!rect) return 0

    if (!animation) {
      return -rect[directionToRectProperty[direction]]
    }

    if (animation === filterAnimationMap.SHOW) {
      return options.showStateDirectionValue ?? 0
    }

    if (animation === filterAnimationMap.HIDE) {
      return -rect[directionToRectProperty[direction]]
    }

    return 0
  }
  const clipRuleTemplate = (coords: string) => `rect(${coords})`

  const buildClipCSSValue = (): string | undefined => {
    if (!rect) return undefined

    let coords = null

    if (!animation) {
      switch (direction) {
        case 'top':
          coords = `0px, ${rect.width}px, ${rect.height}px, 0px`
          break
        case 'left':
          coords = `0px, ${rect.width}px, ${rect.height}px, ${rect.width}px`
      }

      return coords ? clipRuleTemplate(coords) : undefined
    }

    if (animation === filterAnimationMap.SHOW) {
      switch (direction) {
        case 'top':
          coords = `0px, ${rect.width}px, ${rect.height}px, 0px`
          break
        case 'left':
          coords = `0px, ${rect.width}px, ${rect.height}px, 0px`
          break
      }

      // After Show Animation is done, resetting clip state (in case children have more height content)
      setTimeout(() => {
        setAnimationClip(undefined)
      }, options.duration)

      return coords ? clipRuleTemplate(coords) : undefined
    }

    if (animation === filterAnimationMap.HIDE) {
      switch (direction) {
        case 'top':
          coords = `${rect.height}px, ${rect.width}px, ${rect.height}px, 0px`
          break
        case 'left':
          coords = `0px, ${rect.width}px, ${rect.height}px, ${rect.width}px`
      }
      return coords ? clipRuleTemplate(coords) : undefined
    }
  }

  const showAnimation = () => {
    setAnimation(filterAnimationMap.SHOW)
    animationStateRef.current = filterAnimationMap.SHOW
    if (onAnimationChange && typeof onAnimationChange === 'function') {
      onAnimationChange(filterAnimationMap.SHOW)
    }
  }

  const hideAnimation = () => {
    // Returning clip rect before triggering hide animation
    setAnimation(filterAnimationMap.HIDE)
    animationStateRef.current = filterAnimationMap.HIDE

    if (onAnimationChange && typeof onAnimationChange === 'function') {
      onAnimationChange(filterAnimationMap.HIDE)
    }
  }

  const toggleAnimation = () => {
    setAnimation((prev) => {
      if (prev == null) {
        animationStateRef.current = filterAnimationMap.SHOW

        if (onAnimationChange && typeof onAnimationChange === 'function') {
          onAnimationChange(filterAnimationMap.SHOW)
        }

        return filterAnimationMap.SHOW
      }

      const updated =
        filterAnimationMap.SHOW === prev ? filterAnimationMap.HIDE : filterAnimationMap.SHOW

      if (updated === filterAnimationMap.HIDE) {
        switch (direction) {
          case 'top':
            setAnimationClip(clipRuleTemplate(`0px, ${rect?.width}px, ${rect?.height}px, 0px`))
            break
          case 'left':
            setAnimationClip(clipRuleTemplate(`0px, ${rect?.width}px, ${rect?.height}px, 0px`))
            break
        }
      }

      animationStateRef.current = updated

      if (onAnimationChange && typeof onAnimationChange === 'function') {
        onAnimationChange(updated)
      }

      return updated
    })
  }

  return {
    animationState: animation,
    animationCSSProperties: {
      transition: `all ${options.duration}ms cubic-bezier(0.65, 0.05, 0.36, 1) 0s`,
      [direction]: animationDirection,
      clip: animationClip,
    },
    toggleAnimation,
    showAnimation,
    hideAnimation,
    rect,
  }
}
