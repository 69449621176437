import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {observer} from 'mobx-react-lite'
// @ts-ignore Route conflict
import {useLocation, useRouteMatch} from 'react-router-dom'
import useEinstein from '../../../store/hooks/useEinstein'
import {Box, Flex, Stack, Text, Container, BaseButton} from '../../../vanilla'
import {Breadcrumbs} from '../../breadcrumbs'

import {
  InstantSearch as AlgoliaInstantSearch,
  useInstantSearch,
  InstantSearchSSRProvider,
} from 'react-instantsearch-hooks-web'

import {Hits} from './hits'
import {Stats} from './stats'
import {useCategoryStore, useContentStore, useGlobalStore} from '../../../store/hooks/useStore'
import {Filters} from './filters'
import {Pagination} from './pagination'
import algoliaRouting from '../routing'
import {Link} from '../../link'
import {PLPSkeletonTiles} from '../../skeletons'
import StructuredDataType, {
  buildBreadcrumbsStructuredData,
  buildProductListStructuredData,
} from '../../../utils/structured-data'
import {Seo} from '../../seo'
import {RefinementsDisplay} from './refinements-display'
import * as styles from './styles.css'
import {quickLinksContainer, searchSuggestionItem} from './styles.css'
import debounce from 'lodash.debounce'
import {useViewItemListTracking} from '../../../analytics/view-item-list-tracking'
import {flowResult} from 'mobx'
import {Slot} from '../../cms/Slot'
import {useSelectPromotionTracking} from '../../../analytics/promotion-tracking'
import {Scrollbar} from '../../scrollbar/scrollbar'
import {isMobile} from '../../../utils/utils'

export interface InstantSearchProps {
  initialUiState?: Record<string, any>
  category?: {name: string; id: string}
  children?: any
  contentAboveHeader?: React.ReactNode
  hasCategoryHeadlineBanner?: boolean
  h2?: {heading2: string; textAlignment?: 'left' | 'right' | 'center'}
  presentationAttributes?: {
    hideBreadCrumbs: boolean
    hideExpiredPromotions: boolean
    hideProductListing: boolean
    hideSearchSuggestions: boolean
    hideTitle: boolean
  }
  pageName?: string
  categoryTree?: object
  seo?: {
    pageTitle: string
    pageDescription: string
  }
  serverState?: any
  inGridPromos?: any
  serverUrl?: string
}

const InstantSearch = observer(
  ({
    initialUiState,
    category,
    children,
    contentAboveHeader,
    hasCategoryHeadlineBanner,
    inGridPromos,
    serverState,
    serverUrl,
    h2,
    pageName,
    presentationAttributes,
    categoryTree,
    seo,
  }: InstantSearchProps) => {
    const {algoliaIndexBase, searchClient} = useGlobalStore()
     const searchIndex = `${algoliaIndexBase}__products__default`
    const routing = algoliaRouting(serverUrl!, searchIndex, algoliaIndexBase)

    return (
      <InstantSearchSSRProvider {...serverState}>
        <AlgoliaInstantSearch searchClient={searchClient} indexName={searchIndex} routing={routing}>
          {children}
          <Stack spacing={['24px']} paddingBottom={'20px'}>
            <ResultsHeader
              category={category}
              hasCategoryHeadlineBanner={hasCategoryHeadlineBanner}
              content={contentAboveHeader}
              presentationAttributes={presentationAttributes}
              h2={h2}
              pageName={pageName}
            />
            <Box aria-hidden borderBottom="1px" borderColor="border0" />
            <Box display={['none', 'block']}>
              <RefinementsDisplay category={category!} />
            </Box>
            <Results inGridPromos={inGridPromos} categoryTree={categoryTree} seo={seo} />
            <Pagination />
          </Stack>
        </AlgoliaInstantSearch>
      </InstantSearchSSRProvider>
    )
  },
)

export default InstantSearch

interface ResultsHeaderProps {
  category?: {
    id: string
    name: string
  }
  content?: React.ReactNode
  h2?: {heading2: string; textAlignment?: 'left' | 'right' | 'center'}
  hasCategoryHeadlineBanner?: boolean
  presentationAttributes?: {
    hideBreadCrumbs: boolean
    hideExpiredPromotions: boolean
    hideProductListing: boolean
    hideSearchSuggestions: boolean
    hideTitle: boolean
  }
  pageName?: string
}

export function ResultsHeader({
  category,
  content,
  h2,
  hasCategoryHeadlineBanner,
  presentationAttributes,
  pageName,
}: ResultsHeaderProps) {
  const search = useInstantSearch()
  const {fetchItemByKey} = useContentStore()

  const {isExact: isSearchPage} = useRouteMatch('/search') || {isExact: false}

  const query = search.indexUiState.query || search.indexUiState.configure?.query

  const [searchBannerContentKey, setSearchBannerContentKey] = useState<string | null>(null)


  useEffect(() => {
    ;(async function () {
      try {
        const {results} = search || {}

        if (results && isSearchPage) {
          const searchPageBannerDeliveryKey = results?.userData?.find(
            (userDataObj: Record<string, unknown>) => userDataObj.bannerDeliveryKey,
          )

          if (searchPageBannerDeliveryKey) {
            await flowResult(fetchItemByKey(searchPageBannerDeliveryKey.bannerDeliveryKey))
            setSearchBannerContentKey(searchPageBannerDeliveryKey.bannerDeliveryKey)
          }
          else{
            setSearchBannerContentKey(null)
          }
        }
      } catch (error) {
        console.log('Error during fetching Search Page banner content key', error)
        setSearchBannerContentKey(null)
      }
    })()
  }, [search?.results])

  return (
    <Flex direction={['column', 'row']} gap="16px">
      <Stack spacing="0px" flex="1" width="full">
        <Container
          top="0px"
          paddingX="0px"
          position="relative"
          display={category?.name ? 'none' : 'block'}
        >
          <Box paddingY="12px">
            <Breadcrumbs search={query} />
          </Box>
          {searchBannerContentKey && <Slot deliveryKey={searchBannerContentKey} />}
        </Container>
        <Box
          marginTop="8px"
          marginBottom={[
            content ? '8px' : '24px',
            content && hasCategoryHeadlineBanner ? (h2?.heading2 ? '24px' : '48px') : '8px',
          ]}
          zIndex="docked"
        >
          <Text
            as="h1"
            variant="heading5"
            color="accent0"
            lineHeight="taller"
            zIndex="header"
            textDecoration="none"
          >
            {pageName || category?.name || `Search results for '${query}'`}
          </Text>
          {h2 && (
            <Text as="h2" variant="text4" textAlign={h2?.textAlignment}>
              {h2?.heading2}
            </Text>
          )}
        </Box>
        {content}
        {!presentationAttributes?.hideSearchSuggestions && (
          <SearchSuggestions category={category} isContent={!!content}/>
        )}
        <Flex
          direction={['column-reverse', 'row']}
          justifyContent="space-between"
          alignItems="center"
        >
          <Flex
            paddingTop={['16px', '0px']}
            marginTop={['16px', '0px']}
            alignSelf={['flex-start', 'center']}
            width={['full', 'auto']}
            borderTop={['1px', '0px']}
            borderColor="gray200"
          >
            <Stats />
          </Flex>
          <Filters category={category} />
        </Flex>
      </Stack>
    </Flex>
  )
}

export function Results(props: any) {
  const location = useLocation()
  const {sendViewSearch, sendViewCategory} = useEinstein()
  const globalStore = useGlobalStore()

  const search = useInstantSearch()
  const {results} = search

  const {isExact: isSearchPage} = useRouteMatch(['/search']) || {isExact: false}

  const structuredData = useMemo(
    () => [
      buildBreadcrumbsStructuredData(props?.categoryTree),
      buildProductListStructuredData(results.hits),
    ],
    [location.pathname, results],
  )

  const [isInitialLoad, setInitialLoad] = useState(true)
  const sendViewItemListData = useViewItemListTracking()
  const debouncedSendViewEvents = useCallback(
    debounce((results: ReturnType<typeof useInstantSearch>['results']) => {
      // Einstein view search data
      if (isSearchPage) {
        sendViewSearch({
          searchText: results.query,
          products: results.hits.map((hit) => ({id: hit.id})),
        })
        // GA search view item list data
        sendViewItemListData(results.hits, results.query)
      } else if (props?.categoryTree?.length) {
        const viewingCategory = props.categoryTree[props.categoryTree.length - 1]
        // Einstein view category data
        sendViewCategory({
          category: {
            id: viewingCategory.id,
          },
          products: results.hits.map((hit) => ({id: hit.id})),
          itemRange: results.page * results.hitsPerPage,
        })
        // GA category view item list data
        if (isInitialLoad || results.page > 0) {
          sendViewItemListData(results.hits, viewingCategory)
          setInitialLoad(false)
        }
      }
    }, 1000),
    [],
  )

  /** Einstein & GA view for PLP pages.
   * Tracks products that are displayed on page, respects pagination parameter change
   * */
  useEffect(() => {
    if (!results?.hits?.length || !globalStore.dataLoadedForAccuratePricingAndAvailabilty) return
    debouncedSendViewEvents(results)
    return () => {
      debouncedSendViewEvents.cancel()
    }
  }, [results, globalStore.dataLoadedForAccuratePricingAndAvailabilty])

  // Determine the canonical URL
  let categoryBaseUrl = ''
  if (props && props.categoryTree && props.categoryTree.length > 0) {
    categoryBaseUrl = props.categoryTree[props.categoryTree.length - 1].url
  } else if (typeof window !== 'undefined' && !window.location.pathname.includes('search')) {
    categoryBaseUrl = location.pathname.split('?')[0]
  }

  return results.hits.length <= 0 && results.processingTimeMS < 1 ? (
    <Box className={styles.hitsGrid}>
      <PLPSkeletonTiles />
    </Box>
  ) : (
    <>
      <Seo
        title={props?.seo?.pageTitle}
        description={props?.seo?.pageDescription}
        structuredData={structuredData as StructuredDataType}
        canonical={categoryBaseUrl}
      />
      <Hits
        inGridPromos={props.inGridPromos}
        categoryId={props?.categoryTree?.[props?.categoryTree?.length - 1]?.id ?? null}
      />
    </>
  )
}

interface SearchSuggestionsProps {
  category?: {name: string; id: string}
  isContent?: boolean
}

const SearchSuggestions = observer((props: SearchSuggestionsProps) => {
  const {category, isContent} = props

  const {categories} = useCategoryStore()
  const sendSelectPromotion = useSelectPromotionTracking()
  const {routeMap} = useContentStore()
  const categoryData = category?.id ? categories[category.id] : null
  const {results} = useInstantSearch()
  const isMobileFlag = isMobile()
  const categoriesItems = useMemo(() => {
    return (
      categoryData?.categories?.map((categoryId) => {
        const [route] =
          Object.entries(routeMap).find(([route, dataStr]) => {
            const [_, _entityType, entityId] = dataStr.split('|')

            return entityId === categoryId
          }) || []

        return {
          path: route,
          value: categoryId,
          label: categories[categoryId].name || '',
          isRefined: false,
        }
      }) || []
    )
  }, [categoryData, routeMap])

  if (!categoriesItems.length) {
    return null
  }

 const availableSubcategoriesId = results?.facets?.find(obj => obj.name 
  === 'categoryPageId')
 const filteredCategoriesItems = categoriesItems.filter(item => availableSubcategoriesId && availableSubcategoriesId?.data && availableSubcategoriesId?.data[item.value])
const promotions = filteredCategoriesItems.map((categoryItem, index) => {

    return {
      creative_name: category?.name,
      creative_slot: 'Sub Category links',
      promotion_id: categoryItem.path,
      promotion_name: categoryItem.label,
      link_position: index + 1 ?? 1
    }
  })

  if (!filteredCategoriesItems.length) {
    return null
  }

  // Only show the scrollbar on mobile view
  return (
    <Scrollbar showScrollbar={isMobileFlag}>
      <Flex 
        className={quickLinksContainer}
        marginTop={isContent ? '20px' : '0px'}
        >
        {filteredCategoriesItems.map((categoryItem, index) => (
            <BaseButton
              key={categoryItem.value}
              variant="unstyled"
              as={Link}
              to={categoryItem.path}
              extraClassName={searchSuggestionItem}
              onClickCapture={() => sendSelectPromotion(promotions[index])}
            >
              <Text fontSize="xs" weight="normal" variant="unstyled">
                {categoryItem.label}
              </Text>
            </BaseButton>
        ))}
      </Flex>
    </Scrollbar>
)})
