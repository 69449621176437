import React from 'react'
import {Layout} from '../layout'
import {Box, Container, Flex, Select, Stack, Text} from '../../vanilla'
import ProductTileSkeleton from '../product-tile-skeleton'
import {ChevronDownIcon, ChevronRightIcon} from '../icons'
import * as styles from './styles.css'
import {BaseButton} from '../../vanilla'
import {SliderIcon} from '../icons/SliderIcon'

export const PLPSkeletonLayout = ({children}) => {
  return (
    <Layout>
      <Container padding="0px">
        <Container position="relative" paddingX="0px" paddingBottom="60px">
          <Container top="0px" paddingX="0px" zIndex="header" position="relative">
            {children}
          </Container>
        </Container>
      </Container>
    </Layout>
  )
}

export const PLPSkeletonBreadcrumbs = () => {
  return (
    <Box paddingY="16px">
      <Box as="nav" aria-label="breadcrumb">
        <Stack
          direction="row"
          align="center"
          as="ol"
          display={['inline-block', 'flex']}
          width="full"
          spacing="0px"
        >
          {[43, 50, 129].map((number, index) => (
            <Flex as="li" key={index} display={['inline-flex', 'flex']} align="center">
              <Box bg={'gray100'} style={{height: '15px', width: `${number}px`}} />
              {index !== 2 && (
                <ChevronRightIcon
                  className={styles.breadcrumbIconSkeleton}
                  boxSize="12px"
                  marginX="4px"
                  display="inline-block"
                />
              )}
            </Flex>
          ))}
        </Stack>
      </Box>
    </Box>
  )
}

export const PLPSkeletonStatsFilters = () => {
  return (
    <>
      <Flex
        display={['none', 'flex']}
        paddingTop={['16px', '0px']}
        marginTop={['16px', '0px']}
        alignSelf={['flex-start', 'center']}
        width={['full', 'auto']}
        borderTop={['1px', '0px']}
        borderColor="gray200"
      ></Flex>
      <Flex direction={['column', 'row']} gap="16px">
        <Stack spacing="0px" flex="1" width="full">
          <Container
            top="0px"
            paddingX="0px"
            zIndex="header"
            position="relative"
            display={'block'}
          ></Container>
          <Box marginTop="12px" marginBottom={['24px', '12px']} zIndex="docked">
            <Box bg={'gray100'} style={{height: '30px', width: '294px'}} />
          </Box>

          <Flex
            marginBottom={'24px'}
            direction={['column-reverse', 'row']}
            marginTop={'0px'}
            justifyContent="space-between"
            alignItems="center"
          >
            <Flex
              marginBottom={['12px', '0px']}
              paddingTop={['24px', '0px']}
              marginTop={['16px', '0px']}
              alignSelf={['flex-start', 'center']}
              width={['full', 'auto']}
              borderTop={['1px', '0px']}
              borderColor="gray200"
            >
              <Box bg={'gray100'} style={{height: '20px', width: '215px'}} />
            </Flex>
            <Flex gap="20px" className={styles.filtersContainerSkeleton} align="center">
              <Box position="relative" display={['none', 'block']} style={{width: 135}}>
                <BaseButton
                  width="full"
                  paddingX="12px"
                  variant="unstyled"
                  border="1px"
                  borderColor={'gray200'}
                  height="36px"
                  background="white"
                  justifyContent="space-between"
                >
                  <Text variant="text3">Filters</Text>
                  <SliderIcon boxSize="16px" color={'gray800'} />
                </BaseButton>
              </Box>
              <Box display={['block', 'none']} width="half">
                <BaseButton
                  width="full"
                  paddingX="12px"
                  variant="unstyled"
                  border="1px"
                  borderColor={'gray200'}
                  height="36px"
                  background="white"
                  justifyContent="space-between"
                >
                  <Text variant="text3">Filters</Text>
                  <SliderIcon boxSize="16px" color={'gray800'} />
                </BaseButton>
              </Box>
              <Box position="relative" width="half">
                <Select
                  variant="unstyled"
                  style={{height: 36, border: '1px solid #DDD'}}
                  paddingY="2px"
                  paddingRight="32px"
                  paddingLeft="12px"
                  background="white"
                  border="1px"
                  borderColor="gray200"
                >
                  <option>Sort by</option>
                </Select>
              </Box>
            </Flex>
          </Flex>
        </Stack>
      </Flex>
    </>
  )
}

export const PLPSkeletonTiles = ({count = 24}) => {
  const skeletonTiles = Array.from({length: count}, (_, i) => <ProductTileSkeleton key={i} />)

  return <>{skeletonTiles}</>
}

export const PLPSkeletonGrid = ({children}) => {
  return (
    <Stack spacing={['24px']} paddingBottom={'20px'}>
      <Box aria-hidden borderBottom="1px" borderColor="border0" />
      <Box className={styles.hitsGridSkeleton}>{children}</Box>
    </Stack>
  )
}
