import React, {Fragment} from 'react'
import {observer} from 'mobx-react'
//@ts-ignore
import {useRouteMatch} from 'react-router-dom'
import {useInstantSearch, useRefinementList} from 'react-instantsearch-hooks-web'
import {Flex, Text} from '../../../vanilla'
import {objectKeys} from '../../../utils/utils'
import {useCategoryStore} from '../../../store/hooks/useStore'
import {SEARCH_DEFAULT_REFINEMENTS} from '../../../utils/constants'
import {hideScroll} from './styles.css'
import { RefinementItem } from './refinement-item'

interface RefinementsDisplayProps {
  category: {id: string; name: string}
}

export const RefinementsDisplayContainer = ({category}: RefinementsDisplayProps) => {
  const {
    indexUiState: {refinementList = {}},
  } = useInstantSearch()
  const priceRefine = SEARCH_DEFAULT_REFINEMENTS.PRICE
  const brandRefine = SEARCH_DEFAULT_REFINEMENTS.BRAND
  const {refine: PriceRefinements} = useRefinementList({attribute: priceRefine.attribute})
  const {refine: BrandRefinements} = useRefinementList({attribute: brandRefine.attribute})


  const {categoryRefinements} = useCategoryStore()
  const {isExact: isSearchPage} = useRouteMatch('/search') || {isExact: false}
  const refinements = isSearchPage ? SEARCH_DEFAULT_REFINEMENTS : categoryRefinements[category?.id]

  return (
    <Flex className={hideScroll} flexWrap="wrap" align="center" gap="8px">
      {objectKeys(refinementList).map((refinementAttr, idx) => {
        const {label} =
          refinements ? Object.values(refinements).find(({attribute}) => attribute === refinementAttr) || {} : {}

        const hasRefinements = refinementList[refinementAttr].length

        return label && hasRefinements ? (
          <Fragment key={`${refinementAttr}-${idx}`}>
            <Text variant="text3" marginLeft={idx !== 0 ? '20px' : '0px'} marginRight="8px">
              {label}
            </Text>
            {refinementList[refinementAttr].map((refinement, index) => (
              <RefinementItem refinement={refinement} refine={label === priceRefine.label ? PriceRefinements : BrandRefinements} refinementAttr={refinementAttr} index={index} />
            ))}
          </Fragment>
        ) : null
      })}
    </Flex>
  )
}

export const RefinementsDisplay = observer(RefinementsDisplayContainer)
