import React, {useEffect, useRef} from 'react'
import {useLocation, useRouteMatch} from 'react-router-dom'
import {observer} from 'mobx-react'
import {useClearRefinements, useSortBy} from 'react-instantsearch-hooks-web'
import {useCategoryStore, useGlobalStore} from '../../../store/hooks/useStore'
import {Flex, Select} from '../../../vanilla'
import {filtersContainer} from './styles.css'
import {DesktopRefinements} from './refinements/desktop-refinements'
import {MobileRefinements} from './refinements/mobile-refinements'
import {SEARCH_DEFAULT_REFINEMENTS} from '../../../utils/constants'

interface FiltersProps {
  category?: {id: string; name: string}
}

export const FiltersContainer = ({category}: FiltersProps) => {
  const {isExact: isSearchPage} = useRouteMatch('/search') || {isExact: false}
  const {algoliaIndexBase} = useGlobalStore()
  const {getRefinementsByCategoryId, categoryRefinements, categories, status} = useCategoryStore()
  const {refine, canRefine} = useClearRefinements()
  const previousParentCategoryId = useRef<string | null>()
   const {search} = useLocation()

  useEffect(() => {
    // Clear refinements on new search
    refine()
  }, [search])

  const sortByApi = useSortBy({
    items: [],
  })

  const handleSort = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const {value} = e.target
    sortByApi.refine(value)
  }

  let refinements: {label: string; attribute: string}[] = []
  const defaultSelectedValue = sortByApi.currentRefinement
  if (category?.id && !isSearchPage) {
    refinements = categoryRefinements[category?.id]
  } else if (isSearchPage) {
    refinements = Object.values(SEARCH_DEFAULT_REFINEMENTS)
  }

  useEffect(() => {
    try {
      if (category?.id && !categoryRefinements[category.id]) getRefinementsByCategoryId(category.id)
    } catch (error) {
      console.error(`Error fetching refinements: ${error}`)
    }
  }, [category])

  useEffect(() => {
    if (status !== 'done' || !category?.id) {
      // If the categories haven't been fetched yet or If we are on a search page break here
      return
    }
      try {
        const {parentCategoryTree, id} = categories[category?.id]
        if (!previousParentCategoryId.current) {
          previousParentCategoryId.current = id
          return
        }
    
        if (previousParentCategoryId.current && parentCategoryTree?.length) {
          const directParent = parentCategoryTree[parentCategoryTree.length - 1]
    
          if (!directParent) {
            return
          }
    
          if (directParent.id !== previousParentCategoryId.current) {
            canRefine && refine()
            previousParentCategoryId.current = null
          } else {
            previousParentCategoryId.current = directParent?.id
          }
        }
      } catch (error) {
        console.error('Error accessing category:', error);
      }
  }, [status])

  return (
    <Flex gap="20px" className={filtersContainer} align="center">
      <DesktopRefinements refinements={refinements ?? []} category={category} />
      <MobileRefinements refinements={refinements ?? []} category={category} />
      {}
      <Select
        id="sorting-select-field"
        defaultValue={defaultSelectedValue}
        variant="unstyled"
        style={{height: 36, border: '1px solid #DDD'}}
        paddingY="2px"
        paddingRight="32px"
        paddingLeft="12px"
        background="white"
        border="1px"
        borderColor="gray200"
        onChange={handleSort}
        validatedSuccessfully={false}
      >
        <option value={`${algoliaIndexBase}__products__default`} selected>Sort by</option>
        <hr />
        <option value={`${algoliaIndexBase}__products__bestsellers`}>Bestsellers</option>
        <option value={`${algoliaIndexBase}__products__price_asc`}>Price: Low to High</option>
        <option value={`${algoliaIndexBase}__products__price_desc`}>Price: High to Low</option>
        <option value={`${algoliaIndexBase}__products__alpha_asc`}>Product Name: A to Z</option>
        <option value={`${algoliaIndexBase}__products__alpha_desc`}>Product Name: Z to A</option>
      </Select>
    </Flex>
  )
}

export const Filters = observer(FiltersContainer)
