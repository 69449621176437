import React from 'react'

import {ChevronLeftIcon, ChevronRightIcon} from '../../icons'
import {Box, Button, Flex, Text} from '../../../vanilla'
import {usePagination} from 'react-instantsearch-hooks-web'
import {paginationButton, paginationContainer, paginationMiddleBox, paginationChevron} from './styles.css'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export const Pagination = () => {
  const {currentRefinement, nbPages, refine, canRefine, isFirstPage, isLastPage} = usePagination()
  const location = useLocation()

  useEffect(() => {
    refine(0)
  }, [location.pathname])

  const moveToTop = () => {
    const anchor = document.querySelector('#stats-text')
    const coordinates = {top: 0, left: 0}
    if (anchor) {
      coordinates.top = anchor.getBoundingClientRect?.()?.top + window.scrollY
    }

    window.scrollTo({...coordinates, behavior: 'auto'})
  }

  const onPrevious = () => {
    if (canRefine && !isFirstPage) {
      refine(currentRefinement - 1)
      moveToTop()
    }
  }

  const onNext = () => {
    if (canRefine && !isLastPage) {
      refine(currentRefinement + 1)
      moveToTop()
    }
  }
  return (
    <Flex justify="center">
      <Flex className={paginationContainer}>
        <Button
          aria-label="Previous Page"
          variant="unstyled"
          onClick={onPrevious}
          className={paginationButton}
        >
          <ChevronLeftIcon className={paginationChevron} />
        </Button>
        <Box
          className={paginationMiddleBox}
        >
          <Text>
            Page {currentRefinement + 1} of {nbPages}
          </Text>
        </Box>
        <Button
          aria-label="Next Page"
          variant="unstyled"
          onClick={onNext}
          className={paginationButton}
        >
          <ChevronRightIcon className={paginationChevron} />
        </Button>
      </Flex>
    </Flex>
  )
}
