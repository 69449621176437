import React from 'react'
import {Box} from '../../vanilla'
import * as styles from './styles.css'

const ProductTileSkeleton = () => {
  return (
    <Box
      className={styles.hitSkeleton}
      style={{pointerEvents: 'none'}}
      backgroundColor="white"
    ></Box>
  )
}

export default ProductTileSkeleton
