import React, {useState} from 'react'
import {observer} from 'mobx-react-lite'
import {useCategoryStore, useContentStore} from '../../../../../store/hooks/useStore'
import useNavigation from '../../../../../hooks/use-navigation'
import {RefinementsListItem} from '../ui/refinements-list-item'
import {useRefinementList} from 'react-instantsearch-hooks-web'
import {REFINEMENTS_ID_MAP} from '../../../../../utils/constants'

interface Category {
  id: string;
  name: string;
}
interface CategoryRefinementsProps {
  category?: Category | Category [] | null
  isInDrawer: boolean
  label: string
  lastItem: boolean
}

type CategoryRefinementItem = {path?: string; label: string; isRefined: boolean; value: string}

export const CategoryRefinements = observer((props: CategoryRefinementsProps) => {
  const {label, isInDrawer, lastItem} = props
  const {category} = props
  const {routeMap} = useContentStore()
  const {categories} = useCategoryStore()
  const [hasCategories, setHasCategories] = useState(false)
  const {items: primaryCategoryItems} = useRefinementList({
    attribute: 'primary_category_id',
  })
  const {items: categoryPageIdItems} = useRefinementList({
    attribute: REFINEMENTS_ID_MAP.CATEGORY,
  })

  // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
  const categoryData =  category && !(category instanceof Array) ? categories[category?.id!] : categories

  const navigate = useNavigation()

  const categoriesItems = 
 !category ? (Object.values(categories).filter((item)=> item.isTopLevelCategory)): categoryData?.categories
      ?.map((categoryId) => {
        const [route] =
          Object.entries(routeMap).find(([_, dataStr]) => {
            const [, , entityId] = dataStr.split('|')

            return entityId === categoryId
          }) || []

        return {
          path: route,
          value: categoryId,
          label: categories[categoryId].name || '',
          isRefined: false,
        }
      })  || []
     
let filteredCategories = categoriesItems
      const relatedCategories =  categoriesItems.filter((category) => {
        const findRelatedCategories = category.categories ? category.categories.find((categoryItem)=> [...primaryCategoryItems, ...categoryPageIdItems].some(
          (item) => item.value === categoryItem )): [...primaryCategoryItems, ...categoryPageIdItems].some(
            (item) => item.value === category.value)
        return findRelatedCategories

      })
       if (relatedCategories.length > 0) {
        filteredCategories = relatedCategories
        if (!hasCategories) {
        setHasCategories(true)
        }
       }
  const handleCategoryRefinementClick = (item: CategoryRefinementItem) => {
    item.path && navigate(item.path + (window.location?.search ?? ''))
  }
  return (
    <RefinementsListItem
      items={filteredCategories}
      onSelect={handleCategoryRefinementClick}
      hasCategories={hasCategories}
      checkIcon={false}
      alwaysHighlighted={isInDrawer}
      isInDrawer={isInDrawer}
      lastItem={lastItem}
      label={label}
    />
  )
})
