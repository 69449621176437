import React, {useEffect, useRef, useState} from 'react'
import {Box} from '../../vanilla'
import {scrollbarThumb, scrollbarTrack, scrollbarBox, scrollbarContainer} from './styles.css'

interface ScrollableContainerProps {
  children: React.ReactNode
  showScrollbar?: boolean
}

export const Scrollbar: React.FC<ScrollableContainerProps> = ({ children, showScrollbar }) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const [thumbPosition, setThumbPosition] = useState<number>(0)

  // Set the thumb position based on the scroll
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const container = scrollContainerRef.current
      if (container) {
        const handleScroll = () => {
          const scrollPercentage = container.scrollLeft / (container.scrollWidth - container.clientWidth)
          setThumbPosition(scrollPercentage * 200)
        }

        container.addEventListener('scroll', handleScroll)

        return () => {
          container.removeEventListener('scroll', handleScroll)
        }
      }
  }
  }, [])

  return (
    showScrollbar ? (
    <Box className={scrollbarContainer}>
      <Box className={scrollbarBox} ref={scrollContainerRef}>
        {children}
      </Box>
      <Box className={scrollbarTrack}>
        <Box
          className={scrollbarThumb}
          style={{
            transform: `translateX(${thumbPosition}%)`,
          }}
        />
      </Box>
    </Box>
    ) : (
      <>{children}</>
    )
  )
}
