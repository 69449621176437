import React from 'react'
import {useConnector} from 'react-instantsearch-hooks-web'
import connectStats from 'instantsearch.js/es/connectors/stats/connectStats'

import type {
  StatsConnectorParams,
  StatsWidgetDescription,
} from 'instantsearch.js/es/connectors/stats/connectStats'
import {Text} from '../../../vanilla'

export type UseStatsProps = StatsConnectorParams

export function useStats(props?: UseStatsProps) {
  return useConnector<StatsConnectorParams, StatsWidgetDescription>(connectStats, props)
}

export function Stats(props: UseStatsProps) {
  const {page, hitsPerPage = 20, nbHits} = useStats(props)

  const getStatsMessage = () => {
    const startIndex = page * hitsPerPage + 1
    // Take the lower value between the end index and the total number of hits
    const endIndex = Math.min((page + 1) * hitsPerPage, nbHits)
    if (nbHits < hitsPerPage) return `Viewing ${startIndex}-${nbHits} of ${nbHits} results`
    return `Viewing ${startIndex}-${endIndex} of ${nbHits} results`
  }

  return (
    <Text id="stats-text" variant="text3" color="gray800" textDecoration="none" lineHeight="taller">
      {getStatsMessage()}
    </Text>
  )
}
