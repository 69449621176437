import React from 'react'
import {CategoryRefinements} from './partials/category-refinements'
import {GeneralRefinements} from './partials/general-refinements'
import {REFINEMENTS_ID_MAP} from '../../../../utils/constants'

interface DynamicRefinementsProps {
  category?: {id: string; name: string}
  attribute: string
  isInDrawer: boolean
  lastItem: boolean
  label: string
}

export const DynamicRefinements = React.memo((props: DynamicRefinementsProps) => {
  const {attribute, category, isInDrawer, label, lastItem} = props

  if (attribute === REFINEMENTS_ID_MAP.CATEGORY) {
    return (
      <CategoryRefinements
        label={label}
        category={category}
        isInDrawer={isInDrawer}
        lastItem={lastItem}
      />
    )
  }

  return (
    <GeneralRefinements
      attribute={attribute}
      label={label}
      lastItem={lastItem}
      isInDrawer={isInDrawer}
    />
  )
})
